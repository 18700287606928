.modal__backdrop {
    background: rgba(0, 0, 0, .65);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 3;
        /* navbar: */
        padding-left: 6rem;
}

.modal__container {
    background: #fff;
    border-radius: 5px;
    max-width: 100%;
    margin: 50px auto;
    padding: 15px;
    padding-bottom: 5px;
    width: 560px;
    min-height: 30%;
    display: flex;
    flex-direction: column;
    margin-top: 35px;
}

.modal__children {
    display: flex;
    margin: 15px 30px 15px 10px;
        padding: 10px 0 10px 0;
        flex-direction: column;
}

.modal__buttons {
    display: flex;
    margin-right: 10px;
    margin-top: auto;
    justify-content: end;
}

.modal__buttons button {
    margin-right: 10px;
}

.modal__title {
    font-weight: 600;
    display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0.3rem 0 0.5rem 10px;
    }
    
    .modal__close_button {
        display: flex;
        justify-content: end;
        margin-right: 0.6rem;
}