.client-data {
    margin-left: 2rem;
    margin-bottom: 4rem;
    padding-left: 1rem;
    padding-right: 2rem;
    padding-top: 1rem;
    display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  border: 1px solid rgba(238, 238, 238, 0);
  border-radius: 8px;
  border-left-color: var(--accents);
  border-left-width: 5px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
}

.client-data #nombre {
    grid-column: 1 / 3;
    grid-row: 1;
  }

.client-data input {
    font-size: 15px;
    font-weight: bolder;
}

.client-data.add {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    margin-right: 3rem;
    padding-top: 1.5rem;
}

.client-title {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    display: flex;
      justify-content: space-between;
    }
    
    .client-title .client-actions {
      justify-content: end;
      display: flex;
      padding: 0.2rem 0 0.2rem 0
    }
    
    .client-actions button {
      margin-left: 0.8rem;
      padding-left: 0.6rem;
      padding-right: 0.6rem;
}

.client-title.os{
    margin-bottom: 2.5rem;
}

.client-grid {
    margin-left: 2rem;
    padding-left: 1rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
  border: 1px solid rgba(238, 238, 238, 0);
  border-left-color: var(--secondary);
  border-left-width: 5px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

#desc-prod {
    grid-column: 1 / 3;
    grid-row: 2;
    width: 100%!important;
  }

  #defecto {
    grid-column: 3 / 5;
    grid-row: 2;
  }

  
#desc-pres {
    grid-column: 1 / 3;
    grid-row: 3;
    width: 100%!important;
    margin-top: 1rem;
  }

  #res-tec {
    grid-column: 3 / 5;
    grid-row: 3;
    margin-top: 1rem;
  }

  #tecnico {
    grid-column: 1 / 2;
    grid-row: 4;
    margin-top: 1rem;
  }

  #estado {
    grid-column: 2 / 3;
    grid-row: 4;
    margin-top: 1rem;
  }
  
  #tecnicoAdd {
    grid-column: 1 / 3;
    grid-row: 3;
    margin-top: 1rem;
  }
  
  #tecnico-selector {
    min-width: 50%;
  }

  #estado-selector {
    min-width: 50%;
  }

  #estadoAdd {
    grid-column: 3 / 5;
    grid-row: 3;
    margin-top: 1rem;
  }

  #precio {
    grid-column: 2 / 4;
    grid-row: 4;
    margin-top: 1rem;
    justify-self: center;
  }

  #entrega {
    grid-column: 4 / 4;
    grid-row: 4;
    margin-top: 1rem;
  }
  

  @media all and (max-width: 767px) {
    #desc-prod {
        grid-column: 1 / 5;
        grid-row: 2;
      }
    
      #defecto {
        grid-column: 1 / 5;
        grid-row: 3;
      }
      
    .client-grid{
        grid-template-columns: repeat(1, 1fr);
    }

    button.add {
        display: block;
        background-color: rgb(20, 120, 11);
        content: '';
    }
  }

button.add {
   float: right;
   background-color: var(--success);;
   min-width: 12%;
   align-items: center;
   justify-content: flex-end;
}

button.save {
  background-color: var(--accents)!important;
  float: left!important;
  /* margin-left: 2rem; */
  filter: opacity(1)!important;
}

div.search-bar {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
}

input.search-bar {
  display: flex;
    justify-content: center;
    margin: auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-left: 10px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 2rem;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
  }
  
  .pagination a.active {
    background-color: var(--accents);
    color: white;
    border-radius: 5px;
  }
  
  .pagination a:hover:not(.active) {
    background-color: rgb(218, 220, 255);
    border-radius: 5px;
  }

  svg.delete{
    color: #ff6d68;
  }

#addClient {
  scroll-behavior: smooth;
}

.page-header .label {
  display: inline!important;
  padding: .2em .5em .2em;
  font-size: 85%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  margin-left: .5em;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.page-header .label.primary {
  background-color: var(--accents);
  filter: grayscale(0.2), opacity(0.8);
  z-index: 0;
}

.page-header .label.secondary {
  background-color: #999;
}

.add-footer {
  padding-left: 1rem;
  padding-right: 1.25rem;
}
