.container {
    margin-top: 1rem;
    padding-left: 10px;
    padding-right: 10px;
}
.responsive-table li {
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
}
.responsive-table .table-header {
    background-color: var(--accents);
    font-size: 16px;
    color: rgb(246, 246, 246);
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    margin-left: 1%;
    margin-right: 1%;
    position: sticky;
    z-index: 1;
    top: 0;
}
.responsive-table .table-row {
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    border: 1px solid transparent;
    margin-left: 2%;
    margin-right: 2%;
    border-left-width: 5px;
    transition: var(--transition-speed);
}

.responsive-table .table-row:hover {
  border: 1px solid rgba(238, 238, 238, 0);
  border-left-color: var(--accents);
  border-left-width: 5px;
  cursor: pointer;
}

.responsive-table .col-1 {
    flex-basis: 10%;
    text-align: center;
    font-weight: bolder;
}
.responsive-table .col-2 {
    flex-basis: 40%;
    text-align: center;
}
.responsive-table .col-3 {
    flex-basis: 20%;
    text-align: center;
}
.responsive-table .col-4 {
    flex-basis: 15%;
    text-align: center;
}
@media all and (max-width: 767px) {
    .responsive-table .table-header {
        display: none;
   }
    .responsive-table li {
        display: block;
   }
    .responsive-table .col {
        flex-basis: 100%;
   }
    .responsive-table .col {
        display: flex;
        padding: 10px 0;
        text-align: center!important;
        flex-basis: 20%;
    }
    .responsive-table .col:before {
        color: #6c7a89;
        padding-right: 4px;
        content: attr(data-label) ':';
        flex-basis: 40%;
        text-align: left;
   }
   .responsive-table .col-4:before {
       color: #ffffff;
  }
}

.container .label {
    display: block!important;
    padding: .2em 0 .3em;
    margin: 0 2em 0 3em;
    font-size: 85%;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .35em;
    flex-basis: 8%;
}

.container .label.primary {
    background-color: var(--accents);
    filter: grayscale(0.2);
    z-index: 0;
}

.container .label.secondary {
    background-color: #999;
}

.client-table {
    padding-inline-start: 0;
}
.client-table li {
    border-radius: 3px;
    padding: 25px 30px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    align-items: center;
}
.client-table .table-header {
    background-color: var(--accents);
    font-size: 16px;
    color: rgb(246, 246, 246);
    font-weight: bolder;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    border: 1px solid transparent;
    border-left-width: 5px;
    position: sticky;
    z-index: 1;
    top: 0;
}
.client-table .table-row {
    background-color: #fff;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    border: 1px solid transparent;
    border-left-width: 5px;
    transition: var(--transition-speed);
}

.client-table .table-row:hover {
  border: 1px solid rgba(238, 238, 238, 0);
  border-left-color: var(--accents);
  border-left-width: 5px;
  cursor: pointer;
}

.client-table .col-1 {
    flex-basis: 10%;
    text-align: center;
    font-weight: bolder;
}
.client-table .col-2 {
    flex-basis: 20%;
    text-align: center;
}
.client-table .col-3 {
    flex-basis: 30%;
    text-align: center;
}
.client-table .col-4 {
    flex-basis: 40%;
    text-align: center;
}
.client-table .col-4.email {
    font-size: 10px;
}
@media all and (max-width: 767px) {
    .client-table .table-header {
        display: none;
   }
    .client-table li {
        display: block;
   }
    .client-table .col {
        flex-basis: 100%;
   }
    .client-table .col {
        display: flex;
        padding: 10px 0;
   }
    .client-table .col:before {
        color: #6c7a89;
        padding-right: 10px;
        content: attr(data-container .label);
        flex-basis: 50%;
        text-align: right;
   }
}

.search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 1.4rem !important;
    padding: auto;
    min-width: 3rem;
}

.search-button svg {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    align-items: center;
    justify-content: center;
}

.filter-select {
    margin: 1.5rem 1rem !important;
    margin-bottom: 1.5rem !important;
}

select.filter-select:active {
    border-color: rgb(211, 211, 211) !important;
}