#login-div {
    margin: auto;
    height: auto; 
}


#login-form {
    border: 1px solid rgb(195, 194, 194);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: ;
    margin: auto;
    max-width: 70vh;
    margin-top: 2rem;
    padding: .5rem;
}

#login input, button {
    display: block;
    margin-bottom: 1rem;
}

#login h1 {
    margin-bottom: 3rem;
}

#login button {
    transition: var(--transition-speed);
    background: var(--accents);
    filter: opacity(0.5);
    border: 1px solid rgb(221, 221, 221);
    border-radius: 4px;
    padding: 0.4rem;
    margin-top: 1rem;
    width: 25%;
    color: rgb(245, 245, 245);
    font-weight: bold;
}
#login button:hover {
    filter: opacity(1);
}

#forgot {
    margin-top: 0.65rem;
    font-size: 12px;
    color: inherit;
    text-decoration: none;
    transition: all 200ms ease-in-out;
    border-bottom: 0.5px solid transparent;
}

#forgot:hover {
    border-color: black;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    margin-block-end: 0px!important;
    background-color: var(--accents);
    color: white;
    height: 4vh;
    font-size: 0.65rem;
    padding-bottom: .25rem;
}

#title svg {
    color: var(--accents)
}

#title {
    filter: opacity(0.7);
    transition: var(--transition-speed);
}

#title:hover {
    filter: opacity(1);
}