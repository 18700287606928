.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
        justify-content: center;
        margin: auto;
        min-height: 100%;
            width: 30vw;
        margin-top: 5rem;
        border: 1px solid rgba(238, 238, 238, 0);
        padding-top: 2.5rem;
        padding-bottom: 3rem;
        border-radius: 8px;
        border-left-color: #89a8f0;
        border-left-width: 5px;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
    }
    
    .dashboard-title {
        margin-bottom: 2.5rem;
}
.dashboard-item {
    display: inline-flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.dashboard-item>* {
    margin-right: 0.5rem;
}
.dashboard-item svg {
    margin-right: 1rem;
}

.dashboard-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 0.1rem;
    margin-top: 2rem !important;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.dashboard-card {
    min-height: 10%;
    height: 20vh;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    width: 13vw;
    border: 1px solid rgba(238, 238, 238, 0);
    padding: 2.5rem;
    border-radius: 8px;
    border-left-color: #89a8f0;
    border-left-width: 5px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
    overflow-wrap: break-word;
}

.dashboard-card h1 {
    margin-bottom: 1rem;
}

.dashboard-card#inprogress {
    grid-column: 1 / 4;
    grid-row: 1;
}

.dashboard-card#waiting {
    grid-column: 3 / 5;
    grid-row: 1;
}

.dashboard-card#finished {
    grid-column: 4 / 7;
    grid-row: 1;
}

.dashboard-table {
    padding-top: 30px;
    grid-column: 2 / 6;
    grid-row: 3;
}