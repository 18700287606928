#site-left-navbar.navbar {
  width: 5rem;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: var(--bg-primary);
  transition: width 200ms ease;
  z-index: 4;
    /* always above everything */
  }
#site-left-navbar.navbar:hover {
  width: 15rem;
}
#site-left-navbar .navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
#site-left-navbar .nav-item {
  width: 100%;
}
#site-left-navbar .nav-item:nth-last-child(2) {
  margin-top: auto;
}
#site-left-navbar .nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
  filter: grayscale(100%) opacity(0.8);
  transition: var(--transition-speed);
}
#site-left-navbar .nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-secondary);
  color: var(--text-secondary);
  font-weight: bold;
}
#site-left-navbar .nav-brand {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 5rem;
    font-size: 70%;
    color: white;
    text-decoration: none;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
#site-left-navbar .os-desc {
  display: none;
  margin-top: 10px;
  font-weight: normal;
  font-size: 10px;
  transition: 1000ms ease-in;
  }
#site-left-navbar.navbar:hover .os-desc {
  display: inherit;
  opacity: 60%;
}
#site-left-navbar .link-text {
  margin-left: 0.25rem;
  transition: 200ms ease;
  filter: opacity(0%);
}
#site-left-navbar .nav-link svg {
  min-width: 2rem;
  margin: 0 1.5rem;
  color: var(--accents);
}
#site-left-navbar.navbar:hover .link-text {
  filter: opacity(100%);
}