.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  min-height: 100%;
  width: 30vw;
  margin-top: 5rem;
  border: 1px solid rgba(238, 238, 238, 0);
  padding-top: 2.5rem;
  padding-bottom: 3rem;
  border-radius: 8px;
  border-left-color: #89a8f0;
  border-left-width: 5px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
}

.stats-title {
  margin-bottom: 2.5rem;
}
.stats-item {
  display: inline-flex;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.stats-item > * {
  margin-right: 0.5rem;
}
.stats-item svg {
  margin-right: 1rem;
}

.stats-container {
  display: flex;
  flex-direction: row;
  margin-top: 2rem !important;
  justify-content: center;
  flex-basis: 100%;
  flex-wrap: wrap;
}

.stats-card {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  padding: 0 20px 0.5rem 20px;
  margin: 1rem 0.5rem;
  background-color: #f2f2f2;
  border: 1px solid rgba(238, 238, 238, 0);
  border-radius: 8px;
  border-left-color: #89a8f0;
  border-left-width: 5px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05);
  overflow-wrap: break-word;
}

.stats-card h1 {
  margin-bottom: 1rem;
}

.stats-card#inprogress {
  grid-column: 1 / 4;
  grid-row: 1;
}

.stats-card#waiting {
  grid-column: 3 / 5;
  grid-row: 1;
}

.stats-card#finished {
  grid-column: 4 / 7;
  grid-row: 1;
}

.stats-table {
  padding-top: 30px;
  grid-column: 2 / 6;
  grid-row: 3;
  width: 100%;
  height: 100%;
  max-height: 50vh;
}
