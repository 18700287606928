body {
  color: black;
  background-color: rgb(231, 231, 231);
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0;
}

*::-webkit-scrollbar-track {
  background: whitesmoke;
}

*::-webkit-scrollbar-thumb {
  background: var(--accents);
  border-radius: 70px;
}

main {
  /* margin & padding for navbar */
  margin-left: 6rem;
  padding: 1rem 3rem 1rem 1rem;
}

a{
  text-decoration: none;
  color: inherit;
}

input:disabled {
  background-color: rgba(239, 239, 239, 0.3);
}

input {
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(243, 243, 243);
  border-radius: 7px;
  margin-top: 0.5rem;
  height: 1rem;
  width: 90%;
  padding: 0.5rem;
  font-family: 'Montserrat';
  font-size: 1.1rem;
}

select {
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(243, 243, 243);
  border-radius: 7px;
  margin-top: 0.5rem;
  padding: 0.5rem;
  font-family: 'Montserrat';
}

div[class^="clientselector__control"] {
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(243, 243, 243);
  border-radius: 7px;
  margin-top: 0.5rem;
  height: 1rem;
  width: 90%;
  font-family: 'Montserrat';
  font-size: 1.1rem;
}

textarea {
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(243, 243, 243);
  border-radius: 7px;
  margin-top: 0.5rem;
  font-family: 'Montserrat';
  font-size: 1.1rem;
  width: 90%;
  padding: 0.5rem;
}

label {
  align-self: auto;
  display: block;
  font-weight: bold;
}

button:disabled {
  background-color: rgba(195, 195, 195, 0.79);
  color:  rgba(255, 255, 255, 0.754);
  border-color: rgba(195, 195, 195, 0.3);
  transition: var(--transition-speed);
  cursor: default;
}

button:disabled:hover {
  filter: opacity(0.8);
}

button {
  background-color: inherit;
  font-family: 'Montserrat', sans-serif;
  transition: var(--transition-speed);
  filter: opacity(0.8);
  border: 1px solid rgb(221, 221, 221);
  border-radius: 4px;
  padding: 0.4rem;
  margin-top: 1rem;
  color: rgb(245, 245, 245);
  font-weight: bold;
  cursor: pointer;
  align-items: center;
}

button:hover {
  filter: opacity(1);
}

h1.page-title{
  display: inline-block;
  margin-left: 2%;
}

hr {
  filter: opacity(0.25);
}

.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 2rem;
}

.pointer {
  cursor: pointer;
}

.icon {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.alert { 
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}

.input-icon {
  position: relative;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 60%;
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
}

.input-icon > input {
  padding-left: 25px;
  padding-right: 0;
}